// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';

const LoadedAuthErrorPage = React.lazy(() =>
  import('./AuthErrorPage').then(({ AuthErrorPage }) => ({ default: AuthErrorPage }))
);

export const LoadableAuthErrorPage = () => {
  useEffect(() => setPageTitle('Error'), []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedAuthErrorPage />
    </Suspense>
  );
};
