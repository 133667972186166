import Typography from '@material-ui/core/Typography';
import { Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import { TERMS_OF_SERVICE_URL } from '../../constants/App';
import { DialogForm, DialogFormActions } from '../../elements/Dialog/Dialog';
import { Button } from '../../elements/Button/Button';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useFinishAccount } from '../../hooks/useUser';
import { useSnackbar } from 'notistack';
import { MESSAGES } from '../../constants/Messages';
import styled from 'styled-components';

const Form = styled(DialogForm)`
  margin-top: .75rem;
  grid-template-columns: 1fr 1fr;
  min-width: 30rem;
`;

const TermsAgreement = styled(FormControlLabel)`
  grid-column: 1 / -1;
`;

export const FinishAccountCollectInfoForm = ({ onSubmitted }) => {
  const { mutateAsync: sendFinishAccountData, isLoading } = useFinishAccount();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const data = new FormData(evt.target);
    const dto = {
      firstName: data.get('firstName') as string,
      lastName: data.get('lastName') as string,
      organization: data.get('organization') as string,
      website: data.get('website') as string
    };

    try {
      await sendFinishAccountData(dto);
      enqueueSnackbar(MESSAGES.SUCCESS.ACCOUNT_SETUP_SUCCESS, { variant: 'success' });
      onSubmitted();
    } catch (e) {
      enqueueSnackbar(MESSAGES.ERROR.ACCOUNT_SETUP_ERROR, { variant: 'error' });
    }
  };

  return <>
    <Typography variant={'body2'}>We need a little more information to finish setting up your account.</Typography>
    <Form onSubmit={handleSubmit}>
      <TextField
        required
        id='first-name'
        name='firstName'
        label='First Name'
        disabled={isLoading}
      />
      <TextField
        required
        id='last-name'
        name='lastName'
        label='Last Name'
        disabled={isLoading}
      />
      <TextField
        required
        id='organization'
        name='organization'
        label='Company'
        disabled={isLoading}
      />
      <TextField
        required
        id='website'
        name='website'
        label='Website'
        disabled={isLoading}
      />
      <TermsAgreement
        control={<Checkbox required />}
        label={<Typography>I have read and agree to the <a target="_blank" href={TERMS_OF_SERVICE_URL}>terms of service</a>.</Typography>}
      />
      <DialogFormActions>
        <Button
          type='submit'
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={15} /> : null}
        >
          Submit
        </Button>
      </DialogFormActions>
    </Form>
  </>;
};
