import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { PaymentsService } from '../services/PaymentsService';
import { ApiResponse } from '../interfaces/Api';
import { CacheKeys } from '../constants/CacheKeys';

export const useGetManageSubscription = () : UseMutationResult<ApiResponse<string>, AxiosError, void> => {
  return useMutation({
    mutationFn: PaymentsService.getManageSubscriptionLink
  });
};

export const useCancelSubscription = () : UseMutationResult<ApiResponse<string>, AxiosError, void> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PaymentsService.cancelSubscription,
    onSettled: () => {
      queryClient.invalidateQueries(CacheKeys.CREDIT_USAGE);
    }
  });
};
