// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedListsPagePage = React.lazy(() => import('./ListsPage').then(({ ListsPage }) => ({ default: ListsPage })));

export const LoadableListsPage = () => {
  useEffect(() => setPageTitle('Lists'), []);

  return <Suspense fallback={<LoadingAnimation />}>
    <LoadedListsPagePage />
  </Suspense>;
};
