// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedNotErrorPage = React.lazy(() =>
  import('./ErrorPage').then(({ ErrorPage }) => ({ default: ErrorPage }))
);

export const LoadableErrorPage = () => {
  useEffect(() => setPageTitle('Error'), []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedNotErrorPage />
    </Suspense>
  );
};
