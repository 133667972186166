import { LinkProps, Link } from 'react-router-dom';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { StyledLink } from './ExternalLink';

const StyledAppLink = StyledLink.withComponent(Link);

interface AppLinkProps extends LinkProps {

}

export const AppLink = ({ ...linkProps } : AppLinkProps) => {
  return <StyledAppLink {...linkProps} />;
};
