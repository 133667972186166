import { Button, CircularProgress } from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import StarIcon from '@material-ui/icons/Star';
import { useGetManageSubscription } from '../../hooks/usePayments';
import { MESSAGES } from '../../constants/Messages';
import { useSnackbar } from 'notistack';

export const FreeTrialUpgradeButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: getManageSubscriptionLink, isLoading } = useGetManageSubscription();

  const handleUpgradeClick = async () => {
    try {
      const response = await getManageSubscriptionLink();
      window.location.href = response.data as string;
    } catch (error) {
      enqueueSnackbar(MESSAGES.ERROR.SUBSCRIPTION_MANAGEMENT, { variant: 'error' });
    }
  };

  return (<>
    <Button
      variant="contained"
      color="primary"
      size="small"
      startIcon={<StarIcon />}
      onClick={handleUpgradeClick}
      disabled={isLoading}
      endIcon={isLoading ? <CircularProgress size={16} /> : null}
    >
        Upgrade
    </Button>
  </>);
};
