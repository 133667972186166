// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';

const LoadedLandingPagePage = React.lazy(() => import('./LandingPage').then(({ LandingPage }) => ({ default: LandingPage })));

export const LoadableLandingPage = () => {
  useEffect(() => setPageTitle('Landing'), []);

  return <Suspense fallback={<LoadingAnimation />}>
    <LoadedLandingPagePage />
  </Suspense>;
};
