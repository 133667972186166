import ProductFruits from 'react-product-fruits';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { UserContext } from '../../contexts/User/User';
import { useContext } from 'react';

export const SupportWidget = () => {
  const user = useContext(UserContext);
  return <ProductFruits projectCode="xJRxzQAbnvHfJz9n" language="en" username={user?.email} />;
};
