export const CacheKeys = {
  ADMIN_DASHBOARD_STATS: 'admin-dashboard',
  ADMIN_USERS: 'admin-users',
  ADMIN_ALL_ORGANIZATIONS: 'admin-all-organizations',
  ADMIN_SEARCH_ORGANIZATIONS: 'admin-search-organizations',
  CONTENT_CONNECT_CLIENTS: 'content-connect-clients',
  CONTENT_CONNECT_CAMPAIGNS_RECENT: 'content-connect-campaigns-recent',
  CONTENT_CONNECT_CLIENT_DETAILS: 'content-connect-client-details',
  EMAIL_CAMPAIGNS_ORGANIZATION_ANALYTICS: 'email-campaigns-organization-analytics',
  EMAIL_CAMPAIGNS_ORGANIZATION_ANALYTICS_SUMMARY: 'email-campaigns-organization-analytics-summary',
  SAVED_SEARCHES: 'saved-searches',
  CONTACTS_SEARCH: 'search-contacts',
  MOVEMENT_SEARCH: 'search-movement',
  COMPANIES_SEARCH: 'search-companies',
  CONTACTS_SEARCH_QUERY: 'contacts-search-query',
  CONTACTS_SEARCH_FILTERS: 'contacts-search-filters',
  COMPANIES_SEARCH_QUERY: 'companies-search-query',
  COMPANIES_SEARCH_FILTERS: 'companies-search-filters',
  INTENT_SEARCH_QUERY: 'intent-search-query',
  INTENT_SEARCH_FILTERS: 'intent-search-filters',
  COMPANY: 'company',
  COMPANY_CONTACTS: 'company-contacts',
  EXPORT_LISTS: 'export-lists',
  USER_SUPPRESSION_LISTS: 'user-suppression-lists',
  CONTACT_DETAILS: 'contact-details',
  USER_GET_TRIGGERS: 'user-get-triggers',
  USER_GET_TRIGGER_INFO: 'user-get-trigger-info',
  SEARCH_SUPPRESSION_LISTS: 'search-suppression-lists',
  RECENTLY_MODIFIED_SUPPRESSION_LISTS: 'recently-modified-suppression-lists',
  INTEGRATION: 'integration',
  CREDIT_USAGE: 'credit-usage'
};
