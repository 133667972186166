import { useEffect, useState } from 'react';

interface UseLoadImage {
  isLoaded: boolean;
  isFailed: boolean;
}

function checkImage (url) {
  return new Promise<boolean>((resolve, reject) => {
    const image = new Image();
    image.onerror = (err) => {
      reject(err);
    };
    image.onload = () => {
      resolve(true);
    };
    image.src = url;
  });
}

export const useLoadImage = (url?: string | null): UseLoadImage => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFailed, setFailed] = useState(false);

  const imageURLReqValidation = async () => {
    setIsLoaded(false);
    setFailed(false);

    try {
      const loaded = await checkImage(url);
      setIsLoaded(loaded);
    } catch (e) {
      setFailed(true);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (url) {
      imageURLReqValidation();
    }
  }, [url]);

  return {
    isLoaded,
    isFailed
  };
};
