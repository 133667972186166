import { createMuiTheme } from '@material-ui/core';
import type {} from '@material-ui/lab/themeAugmentation';

const PRIMARY_COLOR = '#3254bf';
const PRIMARY_COLOR_LIGHT = '#c7d6fa';
const SECONDARY_COLOR = '#50ccc2';
const ACCENT_COLOR = '#222831';
const ACCENT_COLOR_2 = '#2A51E7';
const GRAY = 'rgb(72, 78, 99)';
export const MEDIUM_GRAY = '#b9bcc1';
export const LIGHT_GRAY = '#f0f4fb';
const BASE_FONT_SIZE = 13;
const STANDARD_BOX_SHADOW = '0px 0px 7px -3px rgb(125 125 125 / 34%)';

export const appTheme = createMuiTheme({
  name: 'default',
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: PRIMARY_COLOR_LIGHT
    },
    secondary: {
      main: SECONDARY_COLOR,
      contrastText: '#fff'
    },
    accent: {
      main: ACCENT_COLOR
    },
    accent2: {
      main: ACCENT_COLOR_2
    },
    white: {
      main: '#fff',
      contrastText: PRIMARY_COLOR
    },
    gray: {
      main: GRAY
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    fontWeightBold: 600,
    fontSize: BASE_FONT_SIZE
  },
  contentPadding: '1rem',
  search: {
    border: {
      size: '2px'
    },
    results: {
      boxShadow: STANDARD_BOX_SHADOW,
      borderRadius: '6px'
    },
    paging: {
      borderColor: '#dfe2e9'
    }
  },
  pane: {
    backgroundColor: LIGHT_GRAY
  },
  table: {
    head: {
      borderColor: LIGHT_GRAY
    },
    row: {
      backgroundColor: '#fff',
      hover: {
        backgroundColor: 'rgb(230 230 230 / 20%)'
      },
      alternate: {
        backgroundColor: LIGHT_GRAY
      }
    }
  },
  form: {
    textField: {
      backgroundColor: '#eef0f8'
    }
  },
  unit: {
    rem: 1,
    px: 16
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontSize: BASE_FONT_SIZE
      }
    },
    MuiInputBase: {
      root: {
        borderRadius: '2px'
      }
    },
    MuiFilledInput: {
      root: {
        border: '0',
        borderRadius: '2px',
        backgroundColor: '#eef0f8',
        '& input': {
          '&::placeholder': {
            color: PRIMARY_COLOR,
            opacity: 0.5,
            fontSize: '0.8125rem'
          }
        }
      },
      adornedEnd: {
        paddingRight: '1.375rem'
      },
      underline: {
        '&:before, &:after': {
          display: 'none'
        }
      }
    },
    MuiAutocomplete: {
      input: {
        paddingTop: '10px !important',
        paddingLeft: '.5rem !important',
        paddingBottom: '10px !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: MEDIUM_GRAY,
        '&:hover': {
          borderColor: '#000000'
        },
        '&.Mui-disabled': {
          backgroundColor: 'rgba(0,0,0,0.1)'
        }
      },
      notchedOutline: {
        border: '0'
      }
    },
    MuiSelect: {
      select: {
        paddingTop: '10px',
        paddingLeft: '.5rem',
        paddingBottom: '10px'
      },
      filled: {
        paddingTop: '20px',
        paddingLeft: '.5rem',
        paddingBottom: '8px',
        paddingRight: '20px !important'
      }
    },
    MuiInputLabel: {
      filled: {
        transform: 'translate(10px, 16px)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(10px, 6px) scale(.75)'
        }
      }
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: 600,
        fontSize: '1rem'
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: '.75rem',
        marginBottom: '0.25rem'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '6px'
      },
      elevation1: {
        boxShadow: STANDARD_BOX_SHADOW
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '0.8125rem'
      }
    },
    MuiRadio: {
      root: {
        padding: '0 .25rem 0 0'
      }
    }
  }
});
