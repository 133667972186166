import { createRef, useLayoutEffect, useState, useCallback } from 'react';
import { debounce } from 'underscore';
import { TextField } from '../../elements/Form/TextField/TextField';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import styled from 'styled-components';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

// Component
import { AppSearchBox } from './AppSearchBox';

const AppSearchTextField = styled(TextField)`
  position: relative;

  .MuiInputAdornment-root {
    svg {
      fill: ${(props) => props.theme.palette.primary.main};
      fill-opacity: 0.5;
    }
  }

  input {
    padding-left: 0;
    padding-right: 2rem;
    min-width: 14.5rem;
    width: auto;
    font-size: 1rem;

    &::placeholder {
      text-align: center;
    }
  }

  fieldset {
    top: 0;
    padding-left: 2.5rem !important;
    padding-right: 2rem;
  }
`;

const AppBarSearchContainer = styled.div`
  margin-left: 2rem;
`;

export const AppSearchBar = () => {
  const searchFieldRef = createRef<HTMLInputElement>();
  const [searchBox, setSearchBox] = useState(false);
  const [search, setSearch] = useState('');
  const [dbValue, saveToDb] = useState('');

  const handleKeyPress = (evt: KeyboardEvent) => {
    if (evt.key === 'Enter' && search.length > 2) {
      setSearchBox(true);
    }
  };

  useLayoutEffect(() => {
    if (searchFieldRef && searchFieldRef.current) {
      searchFieldRef.current.addEventListener('keyup', handleKeyPress);
      searchFieldRef.current.addEventListener('click', handleChange);
      return () => {
        searchFieldRef.current?.removeEventListener('keyup', handleKeyPress);
        searchFieldRef.current?.removeEventListener('click', handleChange);
      };
    }
  });

  const debouncedSave = useCallback(
    debounce((nextValue: React.SetStateAction<string>) => saveToDb(nextValue), 1000),
    [] // will be created only once initially
  );

  const handleChange = event => {
    const { value: nextValue } = event.target;
    setSearch(nextValue);
    debouncedSave(nextValue);
    if (search.length > 1) {
      setSearchBox(true);
    } else if (search.length === 0) {
      setSearchBox(false);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} autoComplete="off" style={{ position: 'relative' }}>
      <AppBarSearchContainer>
        <AppSearchTextField
          textFieldProps={{
            id: 'app-search',
            name: 'app-search',
            placeholder: 'Search Companies or Contacts',
            variant: 'filled',
            size: 'small',
            InputProps: {
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }
          }}
          value={search}
          onChange={handleChange}
          ref={searchFieldRef}
        />
      </AppBarSearchContainer>
      {searchBox && search.length > 2 && <AppSearchBox
        search={search.length > 2 && search === dbValue ? dbValue : ''}
        onClose={() => setSearchBox(false)}
      />}
    </form>
  );
};
