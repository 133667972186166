// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';

const LoadedIntegrationSettingsPage = React.lazy(() =>
  import('./IntegrationSettingsPage').then(({ IntegrationSettingsPage }) => ({ default: IntegrationSettingsPage }))
);

export const LoadableIntegrationSettingsPage = () => {
  useEffect(() => setPageTitle('Intent'), []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedIntegrationSettingsPage />
    </Suspense>
  );
};
