// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const SignupUserPage = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup'
      }
    });
  });

  return (<></>);
};
