import { generatePath } from 'react-router-dom';
import { APP_ROUTES, LIST_PAGES, SEARCH_TYPE_PAGES } from '../constants/Routes';

export const getSearchRoute = (searchType: SEARCH_TYPE_PAGES, savedSearchID?: string|number): string => {
  return generatePath(APP_ROUTES.SEARCH_PATTERN(), {
    searchType,
    savedSearchID
  });
};

export const getMovementRoute = (savedSearchID?: string|number): string => {
  return generatePath(APP_ROUTES.SEARCH_PATTERN(), {
    searchType: SEARCH_TYPE_PAGES.MOVEMENT,
    savedSearchID
  });
};

export const getIntentRoute = (savedSearchID?:string|number): string => {
  return generatePath(APP_ROUTES.INTENT_PATTERN(), {
    savedSearchID
  });
};

export const getListPageRoute = (listType: LIST_PAGES) => {
  return generatePath(APP_ROUTES.LISTS_PATTERN(), {
    listType
  });
};

export const getContactDetailPageRoute = (contactId?: string) => {
  return generatePath(APP_ROUTES.CONTACT_DETAILS_PATTERN(), {
    contactId
  });
};

export const getCompanyDetailPageRoute = (companyId?: string) => {
  return generatePath(APP_ROUTES.COMPANY_DETAILS_PATTERN(), {
    companyId
  });
};
