import { User } from '../interfaces/User/User';
import { useContext } from 'react';
import { UserContext } from '../contexts/User/User';
import { isUserInternal } from '../utilities/users';
import { useMutation, UseMutationResult, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { IFinishAccountDTO, UserService } from '../services/UserService';

interface IUseUser {
    user: User|null|undefined;
    isInternal: boolean;
}

export const useUser = () : IUseUser => {
  const user = useContext(UserContext);
  return {
    user,
    isInternal: user ? isUserInternal(user) : false
  };
};

export const useFinishAccount = () : UseMutationResult<void, AxiosError, IFinishAccountDTO> => {
  return useMutation(UserService.finishAccount);
};
