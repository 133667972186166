import mixpanel from 'mixpanel-browser';
import { ANALYTICS_PROPERTIES } from '../constants/Analytics';

const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN || '';

export class MixPanelService {
    public static init = (): Promise<void> => {
      return new Promise((resolve) => {
        mixpanel.init(MIXPANEL_TOKEN, {
          secure_cookie: true,
          loaded: () => resolve()
        });
      });
    }

    public static identify = (userId: string) => {
      mixpanel.identify(userId);
    }

    public static setProfile = (name: string, email: string) => {
      mixpanel.people.set_once({
        $name: name,
        $email: email
      });
    }

    public static setOrganization = (organizationId: string, organizationName: string) => {
      mixpanel.set_group(ANALYTICS_PROPERTIES.ORGANIZATION, organizationId);
      mixpanel.set_group(ANALYTICS_PROPERTIES.ORGANIZATION_NAME, organizationName);
    }

    public static setRole = (role: string) => {
      mixpanel.set_group(ANALYTICS_PROPERTIES.ROLE, role);
    }

    public static setEnvironment = (environment: string) => {
      mixpanel.set_group(ANALYTICS_PROPERTIES.ENVIRONMENT, environment);
    }

    public static trackEvent = <TProperties>(eventName: string, properties?: TProperties) => {
      if (properties) {
        mixpanel.track(eventName, properties);
      } else {
        mixpanel.track(eventName);
      }
    }
}
