// eslint-disable-next-line no-use-before-define
import React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoadingContainerProps {
    isVisible?: boolean
}

const LoadingContainer = styled.div<LoadingContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5f5f5f85;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  ${props => {
    if (!props.isVisible) {
      return `
            opacity: 0;
            z-index: -1;
          `;
    } else {
      return null;
    }
  }}
`;

const LoadingDivSizer = styled.div`
  position: relative;
  height: 7rem;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  overflow: hidden;
`;

const LoadingCircleAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
`;

const LoadingCircle = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.palette.secondary.main};
  border-left: 2px solid ${props => props.theme.palette.primary.main};
  margin: 0 auto;
  transform-origin: center center;
  animation: ${LoadingCircleAnimation} 1000ms linear infinite;
  z-index: 2;
`;

const LoadingText = styled.div`
  position: relative;
  z-index: 1;
  color: #fff;
`;

const TextLetterSlideInOut = (props) => keyframes`
  0% {
    left: -200%;
  }

  30% {
    left: 0%;
  }

  70% {
    left: 0%;
  }

  100% {
    left: 200%;
  }
`;

interface LoadingTextLetterProps {
    delay: string,
}

const LoadingTextLetter = styled.span<LoadingTextLetterProps>`
  position: relative;
  // animation: ${TextLetterSlideInOut} 2000ms infinite;
  // animation-delay: ${props => props.delay}ms;
  // animation-fill-mode: backwards;
`;

const LoadingTextLetterI = styled(LoadingTextLetter)`
  display: inline-block;
  position: relative;

  &:before {
    content: ".";
    position: absolute;
    top: -.625rem;
    color: ${props => props.theme.rvPing};
  }
`;

interface LoadingAnimationProps {
    isVisible?: boolean
}

export const LoadingAnimation = ({ isVisible }: LoadingAnimationProps) => {
  return <LoadingContainer isVisible={isVisible}>
    <LoadingDivSizer>
      <LoadingCircle/>
      <LoadingText>
        <LoadingTextLetter delay='225'>L</LoadingTextLetter>
        <LoadingTextLetter delay='187.5'>o</LoadingTextLetter>
        <LoadingTextLetter delay='150'>a</LoadingTextLetter>
        <LoadingTextLetter delay='112.5'>d</LoadingTextLetter>
        <LoadingTextLetterI delay='75'>i</LoadingTextLetterI>
        <LoadingTextLetter delay='37.5'>n</LoadingTextLetter>
        <LoadingTextLetter delay='0'>g</LoadingTextLetter>
      </LoadingText>
    </LoadingDivSizer>
  </LoadingContainer>;
};
