// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedNotFound = React.lazy(() =>
  import('./NotFoundPage').then(({ NotFoundPage }) => ({ default: NotFoundPage }))
);

export const LoadableNotFoundPage = () => {
  useEffect(() => setPageTitle('Not Found'), []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedNotFound />
    </Suspense>
  );
};
