import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
// eslint-disable-next-line no-use-before-define
import React, { ComponentPropsWithRef, MutableRefObject } from 'react';
import { withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles(theme => {
  return {
    root: {
      display: 'flex',
      '& .MuiOutlinedInput-root': {
        display: 'flex',
        overflow: 'hidden'
      },
      '& input': {
        // backgroundColor: theme.form.textField.backgroundColor,
        padding: '.5rem',
        fontSize: '.8125rem'
      },
      '& fieldset': {
        border: '0px'
      }
    }
  };
}, {
  withTheme: true
})(MuiTextField);

export interface TextFieldProps extends ComponentPropsWithRef<any> {
    textFieldProps: MuiTextFieldProps
}

// eslint-disable-next-line react/display-name
export const TextField = React.forwardRef((props : TextFieldProps, ref: MutableRefObject<any>) => <StyledTextField {...props} {...props.textFieldProps} inputRef={ref} />);
