import { Link } from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { IAppSubNavigationItem, AppSubNavigationList, AppSubNavigationListWrapper } from './AppSubNavigationList';
import { ExternalNavLink, StyledNavLink } from './AppNavigation';
import styled from 'styled-components';

export const LinkWithSubNavWrapper = styled.div`
  position: relative;
  display: block;
  z-index: 1;
  
  ${AppSubNavigationListWrapper} {
    display: none;
  }
  
  &:focus-within,
  &:focus,
  &:hover {
    ${AppSubNavigationListWrapper} {
      display: block;
    }
  }
`;

interface AppLinkWithSubNavigationProps {
    to: string,
    label: string,
    subNavItems: IAppSubNavigationItem[],
    basePath?: string,
    isExternal?: boolean,
}

export const AppLinkWithSubNavigation = ({ to, label, subNavItems, basePath, isExternal } : AppLinkWithSubNavigationProps) => {
  return <LinkWithSubNavWrapper>
    {isExternal && <Link
      component={ExternalNavLink}
      href={to}
      color='textPrimary'
      target='_blank'
    >
      {label}
    </Link>}
    {!isExternal && <Link
      component={StyledNavLink}
      to={to}
      color='textPrimary'
      isActive={(match, location) => {
        return match || location.pathname.includes(basePath);
      }}
    >
      {label}
    </Link>}
    <AppSubNavigationList
      subNavItems={subNavItems}
    />
  </LinkWithSubNavWrapper>;
};
