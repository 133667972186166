// eslint-disable-next-line no-use-before-define
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { PricingTable } from '../PricingTable/PricingTable';

export const SubscriptionOptions = () => {
  return <>
    <Typography variant="h6" align="center">Choose your plan</Typography>
    <Typography align="center">All paid plans require a 12 month commitment.</Typography>
    <PricingTable />
  </>;
};
