// eslint-disable-next-line no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { appTheme } from './theme';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { initSentry } from './utilities/SentryUtil/SentryUtil';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { LoadableErrorPage } from './pages/ErrorPage/LoadableErrorPage';
import { SnackbarProvider } from 'notistack';
import { AnalyticsService } from './services/AnalyticsService';
import { Auth0Provider } from '@auth0/auth0-react';

initSentry(process.env.SENTRY_DSN, process.env.VERSION, process.env.ENVIRONMENT, process.env.SENTRY_TRACES_SAMPLE_RATE);
AnalyticsService.init();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

// https://stackoverflow.com/questions/57108648/how-do-i-access-material-uis-theme-in-styled-component
ReactDOM.render(
  <Auth0Provider
    domain={process.env.auth0.domain}
    clientId={process.env.auth0.clientId}
    authorizationParams={{
      redirect_uri: `${window.location.origin}`,
      audience: 'https://localhost:8080',
      scope: 'openid profile email offline_access'
    }}
    cacheLocation='localstorage'
    useRefreshTokens
  >
    <StylesProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={appTheme}>
          <ThemeProvider theme={appTheme}>
            <ErrorBoundary showDialog fallback={<LoadableErrorPage />}>
              <SnackbarProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </SnackbarProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </StylesProvider>
  </Auth0Provider>
  , document.getElementById('root')
);
