const PAGE_TITLE_BASE = 'TalentView';
const TITLE_SEPARATOR = ' | ';

export const setPageTitle = (pageName: string) => {
  document.title = `${PAGE_TITLE_BASE}${TITLE_SEPARATOR}${pageName}`;
};

export const appendPageTitlePart = (pageName: string) => {
  const titleParts = document.title.split(TITLE_SEPARATOR);

  if (titleParts.length > 2) {
    document.title = `${titleParts[0]}${TITLE_SEPARATOR}${titleParts[1]}${TITLE_SEPARATOR}${pageName}`;
  } else {
    document.title = `${document.title}${TITLE_SEPARATOR}${pageName}`;
  }
};
