// eslint-disable-next-line no-use-before-define
import React, { ComponentProps, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClearBitLogo } from '../ClearBitLogo/ClearBitLogo';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { AppLink } from '../../elements/Links/AppLink';
import { LoadedImage } from '../LoadedImage/LoadedImage';
import { CompaniesQueryHit, ContactsQueryHit } from '../../interfaces/Search/Search';
import { getCompanyDetailPageRoute, getContactDetailPageRoute } from '../../utilities/route-utils';
import { talentViewApi } from '../../services/ApiService';

interface AppSearchBoxProps extends ComponentProps<any> {
  search: string;
}

const AppBarSearchBoxContainer = styled.div`
  width: 720px;
  height: 500px;
  position: absolute;
  top: 40px;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: 20px;
  overflow: auto;
  left: 47px;
  z-index: 9;
`;

const CompanyNameContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
  width: 100%;
  margin-top: 12px;
`;

export const LoadedProfileImage = styled(LoadedImage)`
  width: 3.7rem;
  height: 3.7rem;
  margin-right: ${(props) => props.theme.contentPadding};
  overflow: hidden;
  border-radius: ${(props) => props.theme.search.results.borderRadius};
  img {
    height: 100%;
  }
`;

const AppStyledLink = styled(AppLink)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
`;

export const ProfilePhotoWrap = styled.div`
  width: 3.7rem;
  height: 3.7rem;
  margin-right: ${(props) => props.theme.contentPadding};
  overflow: hidden;
  border-radius: ${(props) => props.theme.search.results.borderRadius};
`;

const CompanyNameDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Detail = styled.a`
  font-weight: 600 !important;
  font-size: 1rem;
  margin: 0;
  svg {
    display: block;
  }
`;

const SmallDetail = styled(Detail)`
  font-size: .8rem;
  
  ${Detail} + &,
  & + ${Detail},
  & + & {
    margin-top: .25rem;
  }
`;

const VerticalCenteredSmallDetail = styled(SmallDetail)`
  display: inline-block;
  color: #909090;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 0%);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
`;

const Heading = styled.h4`
  margin-top: 20px;
  margin-bottom: 18px;
  font-weight: 600;
`;

export const AppSearchBox = ({ search, onClose }: AppSearchBoxProps) => {
  const [response, setResponse] = useState({
    contacts: [],
    companies: []
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (search && search.length > 2) {
      talentViewApi.getAxios().post('/search/header', search, {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
      )
        .then((response) => {
          if (response && response.data && response.data.data) {
            setResponse({
              contacts: response.data.data?.Contacts || [],
              companies: response.data.data?.Companies || []
            });
            setLoading(false);
          }
        });
    }
  }, [search]);

  return (
    <div>
      <Overlay onClick={() => onClose()} />
      <AppBarSearchBoxContainer>
        <LoadingAnimation isVisible={isLoading} />
        <Flex>
          <div style={{ width: '50%', marginRight: '20px' }}>
            <Heading>Contacts</Heading>
            {response && response.contacts && response.contacts.length > 0 ? (
              response.contacts.map((item: ContactsQueryHit, index) => {
                return (
                  <CompanyNameContainer key={index}>
                    <LoadedProfileImage url={item.linkedinPhoto} alt={item.name} />
                    <CompanyNameDetails>
                      <AppStyledLink onClick={() => onClose()} to={getContactDetailPageRoute(item.id)}>
                        <Detail>{item.name}</Detail>
                      </AppStyledLink>
                      <VerticalCenteredSmallDetail>{item.company}</VerticalCenteredSmallDetail>
                      <VerticalCenteredSmallDetail style={{ marginTop: '0' }}>
                        {item.hrFunction}
                      </VerticalCenteredSmallDetail>
                    </CompanyNameDetails>
                  </CompanyNameContainer>
                );
              })
            ) : (
              <VerticalCenteredSmallDetail>No Records found</VerticalCenteredSmallDetail>
            )}
          </div>
          <div style={{ width: '50%', marginLeft: '20px' }}>
            <h4>Companies</h4>
            {response && response.companies && response.companies.length > 0 ? (
              response.companies.map((item: CompaniesQueryHit, index) => {
                return (
                  <CompanyNameContainer key={index}>
                    <ProfilePhotoWrap>
                      <ClearBitLogo companyUrl={item.websiteUrl} />
                    </ProfilePhotoWrap>
                    <CompanyNameDetails>
                      <AppStyledLink onClick={() => onClose()} to={getCompanyDetailPageRoute(item.id)}>
                        <Detail>{item.name}</Detail>
                      </AppStyledLink>
                      <VerticalCenteredSmallDetail>{item.websiteUrl}</VerticalCenteredSmallDetail>
                      <VerticalCenteredSmallDetail style={{ marginTop: '0' }}>{item.state}</VerticalCenteredSmallDetail>
                    </CompanyNameDetails>
                  </CompanyNameContainer>
                );
              })
            ) : (
              <VerticalCenteredSmallDetail>No Records found</VerticalCenteredSmallDetail>
            )}
          </div>
        </Flex>
      </AppBarSearchBoxContainer>
    </div>
  );
};
