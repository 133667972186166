// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { SetupAccountPageProps } from './SetupAccountPage';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedSetupAccountPage = React.lazy(() => import('./SetupAccountPage').then(({ SetupAccountPage }) => ({ default: SetupAccountPage })));

export const LoadableSetupAccountPage = (setupAccountPageProps : SetupAccountPageProps) => {
  useEffect(() => setPageTitle('Account Setup'), []);

  return <Suspense fallback={<LoadingAnimation />}>
    <LoadedSetupAccountPage {...setupAccountPageProps} />
  </Suspense>;
};
