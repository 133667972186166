// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { ContactPageProps } from './ContactPage';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedContactPage = React.lazy(() =>
  import('./ContactPage').then(({ ContactPage }) => ({ default: ContactPage }))
);

export const LoadableContactPage = (contactPageProps: ContactPageProps) => {
  useEffect(() => setPageTitle('Contact'), []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedContactPage {...contactPageProps} />
    </Suspense>
  );
};
