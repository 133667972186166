import { Button as MButton } from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { ComponentProps } from 'react';
import styled from 'styled-components';

export enum ButtonType {
    PRIMARY='primary',
    SECONDARY='secondary',
}

interface ButtonProps extends ComponentProps<any> {
    label?: string,
    color?: ButtonType,
    type?: 'button' | 'submit' | 'reset' | undefined,
    disabled?: boolean,
    onClick?: (evt) => void,
}

export const StyledButton = styled(MButton)`
`;

export const Button = ({ label, children, color = ButtonType.PRIMARY, ...props }:ButtonProps) => {
  return <StyledButton variant="contained" color={color} {...props}>{!!label && label}{!!children && children}</StyledButton>;
};

export const OutlinedButton = ({ label, children, color = ButtonType.PRIMARY, ...props }:ButtonProps) => {
  return <StyledButton variant='outlined' color={color} {...props}>{!!label && label}{!!children && children}</StyledButton>;
};

export const IconButtonText = styled.span`
  margin-left: .5rem;
`;
