// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { IntentSearchPageProps } from './IntentSearchPage';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedIntentSearchPage = React.lazy(() =>
  import('./IntentSearchPage').then(({ IntentSearchPage }) => ({ default: IntentSearchPage }))
);

export const LoadableIntentSearchPage = (IntentSearchPage: IntentSearchPageProps) => {
  useEffect(() => setPageTitle('Intent'), []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedIntentSearchPage {...IntentSearchPage} />
    </Suspense>
  );
};
