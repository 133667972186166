import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FinishAccountCollectInfoForm } from './FinishAccountCollectInfoForm';
import { SubscriptionOptions } from '../SubscriptionOptions/SubscriptionOptions';
import { useCreditsUsage } from '../../hooks/useCredits';

const WelcomeMessage = styled(DialogTitle)`
  position: relative;
  text-align: center;
  
  &:after {
    content: "";
    display: block;
    margin: .2rem auto 0;
    min-height: 4px;
    width: 14rem;
    background-color: ${props => props.theme.palette.secondary.main};
    border-radius: 1rem;
  }
`;

const LoadingContainer = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 24rem;
    min-height: 12rem;
`;

enum FINISH_ACCOUNT_STEPS {
    LOADING,
    COLLECT_INFO,
    SUBSCRIPTION
}

interface IFinishAccountDialogProps {
    open: boolean;
    onClose: () => void;
}

export const FinishAccountDialog = ({ open, onClose } : IFinishAccountDialogProps) => {
  const [currentStep, setCurrentStep] = useState(FINISH_ACCOUNT_STEPS.LOADING);
  const { userCredits } = useCreditsUsage();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (userCredits && userCredits.isInited) {
      if (userCredits?.creditUsage) {
        setCurrentStep(FINISH_ACCOUNT_STEPS.COLLECT_INFO);
      } else {
        setCurrentStep(FINISH_ACCOUNT_STEPS.SUBSCRIPTION);
      }
    }
  }, [userCredits]);

  return <Dialog
    open={open}
    onClose={handleClose}
    disableBackdropClick
    disableEscapeKeyDown
    maxWidth={false}
  >
    <WelcomeMessage>Welcome to TalentView!</WelcomeMessage>
    <DialogContent style={{ minWidth: '4rem', minHeight: '4rem' }}>
      {currentStep === FINISH_ACCOUNT_STEPS.COLLECT_INFO && <FinishAccountCollectInfoForm onSubmitted={handleClose} />}
      {currentStep === FINISH_ACCOUNT_STEPS.SUBSCRIPTION && <SubscriptionOptions />}
      {currentStep === FINISH_ACCOUNT_STEPS.LOADING && <LoadingContainer><CircularProgress size={36} /></LoadingContainer>}
    </DialogContent>
  </Dialog>;
};
