// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedCompanyPage = React.lazy(() =>
  import('./CompanyPage').then(({ CompanyPage }) => ({ default: CompanyPage }))
);
export const LoadableCompanyPage = () => {
  useEffect(() => setPageTitle('Company'), []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedCompanyPage />
    </Suspense>
  );
};
