// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppNavigation } from '../../components/AppNavigation/AppNavigation';
import styled from 'styled-components';
import { LoadableContactPage } from '../ContactPage/LoadableContactPage';
import { LoadableCompanyPage } from '../CompanyPage/LoadableCompanyPage';
import { LoadableAdminPage } from '../AdminPage/LoadableAdminPage';
import { LoadableAdvancedSearchPage } from '../AdvancedSearchPage/LoadableAdvancedSearchPage';
import { LoadableUserSettingsPage } from '../UserProfilePage/LoadableUserSettingsPage';
import { LoadableListsPage } from '../ListsPage/LoadableListsPage';
import { LoadableNotFoundPage } from '../NotFoundPage/LoadableNotFoundPage';
import { LoadableIntentSearchPage } from '../IntentSearchPage/LoadableIntentSearchPage';
import { SupportWidget } from '../../components/Support/SupportWidget';
import { APP_ROUTES } from '../../constants/Routes';
import { LoadableIntegrationSettingsPage } from '../IntegrationSettingsPage/LoadableIntegrationSettingsPage';
import { useAnalyticsUser } from '../../hooks/useAnalytics';
import { UserContext } from '../../contexts/User/User';
import { LoadableEmailCampaignsDashboardPage } from '../EmailCampaignsDashboardPage/LoadableEmailCampaignsDashboardPage';

const AppPageGrid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`;

// eslint-disable-next-line react/prop-types
export const MainAppRouter = () => {
  const user = useContext(UserContext);
  const { setAnalyticsUser } = useAnalyticsUser();

  useEffect(() => {
    if (user) {
      setAnalyticsUser(user.id, user.organizationId, user.organizationName, user.role, user.email, `${user.firstName} ${user.lastName}`);
    }
  }, [user]);

  return (
    <AppPageGrid>
      <AppNavigation />
      <Switch>
        <Route path={APP_ROUTES.ADMIN()}>
          <LoadableAdminPage />
        </Route>
        <Route exact path={APP_ROUTES.SEARCH_PATTERN()}>
          <LoadableAdvancedSearchPage />
        </Route>
        <Route exact path={APP_ROUTES.INTENT_PATTERN()}>
          <LoadableIntentSearchPage />
        </Route>
        <Route exact path={APP_ROUTES.CONTACT_DETAILS_PATTERN()}>
          <LoadableContactPage />
        </Route>
        <Route exact path={APP_ROUTES.COMPANY_DETAILS_PATTERN()}>
          <LoadableCompanyPage />
        </Route>
        <Route exact path={APP_ROUTES.EMAIL_CAMPAIGNS_DASHBOARD()}>
          <LoadableEmailCampaignsDashboardPage />
        </Route>
        <Route exact path={APP_ROUTES.LISTS_PATTERN()}>
          <LoadableListsPage />
        </Route>
        <Route exact path={APP_ROUTES.USER_SETTINGS()}>
          <LoadableUserSettingsPage />
        </Route>
        <Route exact path={APP_ROUTES.INTEGRATION_SETTINGS()}>
          <LoadableIntegrationSettingsPage />
        </Route>
        <Route exact path="*">
          <LoadableNotFoundPage />
        </Route>
      </Switch>
      <SupportWidget />
    </AppPageGrid>
  );
};
