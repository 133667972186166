import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const initSentry = (dsn, version = 'local', environment = 'local', tracesSampleRate = 1) => {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history
      }),
      Sentry.replayIntegration({
        maskAllInputs: false
      })
    ],
    release: version,
    environment,
    tracesSampleRate,
    replaysOnErrorSampleRate: 1.0
  });
};
