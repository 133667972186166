import styled from 'styled-components';
// eslint-disable-next-line no-use-before-define
import React, { ComponentProps, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useLoadImage } from '../../utilities/LoadImageUtil/LoadImageUtil';
import BusinessIcon from '@material-ui/icons/Business';
import { Grid } from '@material-ui/core';

const ClearBitImg = styled.img`
  width: 100%;
  height: auto;
`;

const GridDefaultImgStyled = styled(Grid)`
  color: #b9bcc1;
  flex-basis: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const LogoDiv = styled.div`
  height: 100%;
  width: 100%;
`;

interface ClearBitLogoProps extends ComponentProps<any> {
  companyUrl?: string | null;
}

export const ClearBitLogo = ({ companyUrl, className }: ClearBitLogoProps) => {
  const clearBitUrl: string | null = companyUrl ? `https://logo.clearbit.com/${companyUrl}` : null;
  const { isLoaded, isFailed } = useLoadImage(clearBitUrl);

  return (
    <LogoDiv className={className}>
      {(!clearBitUrl || clearBitUrl) && !isFailed && !isLoaded && <Skeleton variant="rect" height={'100%'} />}
      {clearBitUrl && isLoaded && !isFailed && <ClearBitImg src={clearBitUrl} />}
      {clearBitUrl && isFailed && isLoaded && (
        <Grid container justify="center" alignItems="center" style={{ height: '100%', width: '100%' }}>
          <GridDefaultImgStyled item>
            <BusinessIcon />
          </GridDefaultImgStyled>
        </Grid>
      )}
    </LogoDiv>
  );
};
