export class PUBLIC_ROUTES {
  public static BASE () {
    return '/app';
  }

  public static LOGIN () {
    return `${this.BASE()}/login`;
  }

  public static ACCOUNT_BASE () {
    return `${this.BASE()}/account`;
  }

  public static SETUP_ACCOUNT () {
    return `${this.ACCOUNT_BASE()}/setup-account`;
  }

  public static LANDING () {
    return `${this.BASE()}/landing`;
  }

  public static SIGNUP () {
    return `${this.BASE()}/signup`;
  }

  public static LOGOUT () {
    return `${this.BASE()}/logout`;
  }

  public static AUTH_ERROR () {
    return `${this.BASE()}/auth-error`;
  }
}

export class APP_ROUTES {
  public static BASE () {
    return '/app';
  }

  public static ADMIN () {
    return `${this.BASE()}/admin`;
  }

  public static ADMIN_DEV_TOOLS () {
    return `${this.ADMIN()}/dev-tools`;
  }

  public static ADMIN_USERS () {
    return `${this.ADMIN()}/users`;
  }

  public static ADMIN_ORGANIZATIONS () {
    return `${this.ADMIN()}/organizations`;
  }

  public static ADMIN_CONTENT_CONNECT () {
    return `${this.ADMIN()}/content-connect`;
  }

  public static ADMIN_CONTENT_CONNECT_CLIENT () {
    return `${this.ADMIN_CONTENT_CONNECT()}/:clientId`;
  }

  public static ADMIN_PAYMENTS () {
    return `${this.ADMIN()}/payments`;
  }

  public static SEARCH_BASE () {
    return `${this.BASE()}/search`;
  }

  public static SEARCH_PATTERN () {
    return `${this.SEARCH_BASE()}/:searchType/:savedSearchID?`;
  }

  public static INTENT_BASE () {
    return `${this.BASE()}/intent/search`;
  }

  public static INTENT_PATTERN () {
    return `${this.INTENT_BASE()}/:savedSearchID?`;
  }

  public static CONTACT_DETAILS_BASE () {
    return `${this.BASE()}/contact`;
  }

  public static CONTACT_DETAILS_PATTERN () {
    return `${this.CONTACT_DETAILS_BASE()}/:contactId`;
  }

  public static COMPANY_DETAILS_BASE () {
    return `${this.BASE()}/company`;
  }

  public static COMPANY_DETAILS_PATTERN () {
    return `${this.COMPANY_DETAILS_BASE()}/:companyId`;
  }

  public static EMAIL_CAMPAIGNS_DASHBOARD () {
    return `${this.BASE()}/email-campaigns`;
  }

  public static LISTS_BASE () {
    return `${this.BASE()}/lists`;
  }

  public static LISTS_PATTERN () {
    return `${this.LISTS_BASE()}/:listType`;
  }

  public static INTEGRATION_SETTINGS () {
    return `${this.USER_SETTINGS()}/integrations`;
  }

  public static USER_SETTINGS () {
    return `${this.BASE()}/settings`;
  }
}

export enum SEARCH_TYPE_PAGES {
  COMPANY='company',
  CONTACT='contact',
  MOVEMENT='movement',
}

export enum LIST_PAGES {
  EXPORT='export',
  SUPPRESSION='suppression',
  SAVED_SEARCH='saved-search',
  COMPANY_MATCH='company-match',
  ENHANCEMENT='enhancement'
}
