import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { CreditUsageDTO, UserCredits } from '../interfaces/Credits/Credits';
import { AxiosError } from 'axios';
import { CacheKeys } from '../constants/CacheKeys';
import { CreditsService } from '../services/CreditsService';
import { ApiResponse } from '../interfaces/Api';
import { UserCreditsContext } from '../contexts/Credits/UserCreditsContext';
import { useContext } from 'react';

interface IUseCreditsUsage {
  userCredits?: UserCredits|null|undefined;
  exportUnlockDisabled: boolean;
  isEnterprise: boolean;
  isFreeTier: boolean;
  isOBVPartner: boolean;
  hasCreditLimit: boolean;
}

export const useCreditsUsage = () : IUseCreditsUsage => {
  const userCredits = useContext(UserCreditsContext);
  const isEnterprise = userCredits?.creditUsage?.currentSubscription?.name === 'Enterprise';
  return {
    userCredits,
    isEnterprise,
    isFreeTier: userCredits?.creditUsage?.currentSubscription?.name === 'Free',
    isOBVPartner: userCredits?.creditUsage?.currentSubscription?.name === 'OBV Partner',
    exportUnlockDisabled: userCredits?.creditUsage?.creditsRemaining === 0,
    hasCreditLimit: !!(userCredits?.creditUsage && userCredits?.creditUsage.creditsAllowed > 0)
  };
};

export const useGetCreditsUsage = (enabled = true) : UseQueryResult<CreditUsageDTO, AxiosError> => {
  return useQuery(CacheKeys.CREDIT_USAGE, {
    enabled: enabled,
    queryFn: CreditsService.getCreditUsage
  });
};

export const unlockContactInfo = (contactId: string) : UseMutationResult<ApiResponse<CreditUsageDTO>, AxiosError, {}> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => CreditsService.unlockContactInfo(contactId),
    onSettled: () => {
      queryClient.invalidateQueries(CacheKeys.CREDIT_USAGE);
      queryClient.invalidateQueries([CacheKeys.CONTACT_DETAILS, contactId]);
    }
  });
};

export const useForceCreditUsageRefresh = () => {
  const queryClient = useQueryClient();
  return {
    forceCreditUsageRefresh: () => queryClient.invalidateQueries(CacheKeys.CREDIT_USAGE)
  };
};
