import { MixPanelService } from './MixPanelService';
import { IAnalyticsProperties } from '../interfaces/Analytics/Analytics';
import { getEnvironment } from '../utilities/EnvironmentUtil/environmentUtil';

export interface IAnalyticsEvent<TProperties extends IAnalyticsProperties> {
    eventName: string,
    properties?: TProperties,
}

export class AnalyticsService {
    public static init = async () => {
      await MixPanelService.init();
      MixPanelService.setEnvironment(getEnvironment());
    }

    public static setUserDetails = (userId: string, organizationId: string, organizationName: string, role: string, email: string, name: string) => {
      MixPanelService.identify(userId);
      MixPanelService.setProfile(name, email);
      MixPanelService.setOrganization(organizationId, organizationName);
      MixPanelService.setRole(role);
    }

    public static trackEvent = <TAnalyticsProperties extends IAnalyticsProperties>(event: IAnalyticsEvent<TAnalyticsProperties>) => {
      MixPanelService.trackEvent<TAnalyticsProperties>(event.eventName, event.properties);
    }
}
