import { talentViewApi } from './ApiService';

export interface IFinishAccountDTO {
    firstName: string;
    lastName: string;
    organization: string;
    website: string;
}

const BASE_URL = '/user';

export class UserService {
  public static async finishAccount (dto: IFinishAccountDTO): Promise<void> {
    await talentViewApi.getAxios().post(`${BASE_URL}/finish-account`, dto);
  }
}
