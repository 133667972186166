// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MainAppRouter } from './pages/MainAppRouter/MainAppRouter';
import { AuthenticatedRoute } from './components/AuthenticatedRoute/AuthenticatedRoute';
import GoogleFontLoader from 'react-google-font-loader';
// @ts-ignore
import LogoIcon from './images/branding/TalentView/TalentView-Logo-Icon.png';
import { LoadableSetupAccountPage } from './pages/SetupAccountPage/LoadableSetupAccountPage';
import { LoadableNotFoundPage } from './pages/NotFoundPage/LoadableNotFoundPage';
import { PUBLIC_ROUTES, SEARCH_TYPE_PAGES } from './constants/Routes';
import { getSearchRoute } from './utilities/route-utils';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingAnimation } from './elements/LoadingAnimation/LoadingAnimation';
import { LoadableLandingPage } from './pages/LandingPage/LoadableLandingPage';
import { LoginUserPage } from './pages/LoginUserPage/LoginUserPage';
import { SignupUserPage } from './pages/SignupUserPage/SignupUserPage';
import { LoadableAuthErrorPage } from './pages/AuthErrorPage/LoadableAuthErrorPage';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      logoutParams: { returnTo: window.location.origin + PUBLIC_ROUTES.LOGIN() }
    });
  });

  return <></>;
};

const App = () => {
  useEffect(() => {
    const iconLink = document.createElement('link');
    iconLink.rel = 'shortcut icon';
    iconLink.href = LogoIcon;
    iconLink.type = 'image/x-icon';

    document.head.appendChild(iconLink);
  }, []);

  const { isLoading } = useAuth0();

  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Open Sans',
            weights: [400, 600, 700]
          }
        ]}
      />
      {isLoading && <LoadingAnimation isVisible />}
      {!isLoading &&
        <Switch>
          <Route exact path="/">
            <Redirect to={getSearchRoute(SEARCH_TYPE_PAGES.CONTACT)} />
          </Route>
          <Route exact path={PUBLIC_ROUTES.LOGIN()}>
            <LoginUserPage />
          </Route>
          <Route exact path={PUBLIC_ROUTES.SIGNUP()}>
            <SignupUserPage />
          </Route>
          <Route exact path={PUBLIC_ROUTES.LANDING()}>
            <LoadableLandingPage />
          </Route>
          <Route exact path={PUBLIC_ROUTES.SETUP_ACCOUNT()}>
            <LoadableSetupAccountPage />
          </Route>
          <Route exact path={PUBLIC_ROUTES.LOGOUT()}>
            <Logout />
          </Route>
          <Route exact path={PUBLIC_ROUTES.AUTH_ERROR()}>
            <LoadableAuthErrorPage />
          </Route>
          <AuthenticatedRoute>
            <MainAppRouter />
          </AuthenticatedRoute>
          <Route exact path="*">
            <LoadableNotFoundPage />
          </Route>
        </Switch>
      }
    </>
  );
};

export default App;
