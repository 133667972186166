// eslint-disable-next-line no-use-before-define
import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { getAsREM } from '../../utilities/StylesUtil/StylesUtil';

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.accent2.main};
  font-size: ${props => getAsREM(props.theme.typography.fontSize)}rem;
  
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

interface ExternalLinkProps extends ComponentProps<any> {

}

export const ExternalLink = ({ ...componentProps } : ExternalLinkProps) => {
  return <StyledLink {...componentProps} />;
};
