// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import styled from 'styled-components';

// @ts-ignore
import LogoNormal from '../../images/branding/TalentView/TalentView-Logo.png';
// @ts-ignore
import LogoWhite from '../../images/branding/TalentView/TalentView-Logo-White.png';
import { ComponentProps } from 'react';

const LOGO_ALT = 'TalentView';

export enum LogoSize {
  EXTRA_LARGE,
  LARGE,
  MEDIUM,
  SMALL
}

export enum LogoColor {
  NORMAL,
  WHITE
}

const LogoImage = styled.img`
    display: block;
`;

const ExtraLargeLogo = styled(LogoImage)`
  height: auto;
  width: 25rem;
`;

const LargeLogo = styled(LogoImage)`
  height: auto;
  width: 18rem;
`;

const MediumLogo = styled(LogoImage)`
  height: auto;
  width: 12.5rem;
`;

const SmallLogo = styled(LogoImage)`
  height: auto;
  width: 8rem;
`;

const getLogoByColor = (color: LogoColor) => {
  switch (color) {
    case LogoColor.WHITE:
      return LogoWhite;
    case LogoColor.NORMAL:
    default:
      return LogoNormal;
  }
};

interface LogoProps extends Partial<ComponentProps<any>> {
  size: LogoSize;
  color?: LogoColor;
}

export const Logo = ({ size, color = LogoColor.NORMAL, className }: LogoProps) => {
  const logo = getLogoByColor(color);

  return <>
    {size === LogoSize.EXTRA_LARGE &&
      <ExtraLargeLogo className={className} src={logo} alt={LOGO_ALT} />
    }
    {size === LogoSize.LARGE &&
      <LargeLogo className={className} src={logo} alt={LOGO_ALT} />
    }
    {size === LogoSize.MEDIUM &&
      <MediumLogo className={className} src={logo} alt={LOGO_ALT} />
    }
    {size === LogoSize.SMALL &&
      <SmallLogo className={className} src={logo} alt={LOGO_ALT} />
    }
  </>;
};
