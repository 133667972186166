import { useLoadImage } from '../../utilities/LoadImageUtil/LoadImageUtil';
import { Skeleton } from '@material-ui/lab';
// eslint-disable-next-line no-use-before-define
import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Grid } from '@material-ui/core';

const LoadedImageTag = styled.img`
  width: 100%;
  height: auto;
`;

const GridDefaultImgStyled = styled(Grid)`
  color: #b9bcc1;
  flex-basis: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const ImageDiv = styled.div`
  height: 100%;
  width: 100%;
`;

interface LoadedImageProps extends ComponentProps<any> {
  url?: string;
}

export const LoadedImage = ({ url, className }: LoadedImageProps) => {
  const { isLoaded, isFailed } = useLoadImage(url);

  return (
    <ImageDiv className={className}>
      {(!url || (isLoaded && isFailed)) && (
        <Grid container justify="center" alignItems="center" style={{ height: '100%', width: '100%' }}>
          <GridDefaultImgStyled item>
            <AccountBoxIcon/>
          </GridDefaultImgStyled>
        </Grid>
      )}
      {url && !isLoaded && <LoadingImageSkeleton />}
      {url && isLoaded && !isFailed && <LoadedImageTag src={url}/>}
    </ImageDiv>
  );
};

export const LoadedImageSkeleton = ({ className } : LoadedImageProps) => (
  <ImageDiv className={className}>
    <LoadingImageSkeleton />
  </ImageDiv>
);

export const LoadingImageSkeleton = () => <Skeleton variant="rect" height={'100%'}/>;
