// eslint-disable-next-line no-use-before-define
import React from 'react';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles(theme => {
  return {
    tooltip: {
      backgroundColor: theme.palette.accent2.main,
      fontWeight: 600,
      color: '#fff'
    },
    arrow: {
      color: theme.palette.accent2.main
    }
  };
}, {
  withTheme: true
})(MuiTooltip);

interface TooltipProps extends MuiTooltipProps {
}

export const Tooltip = ({ title, children, ...allOtherProps } : TooltipProps) => {
  return <StyledTooltip arrow placement='top' title={title} {...allOtherProps}>{children}</StyledTooltip>;
};
