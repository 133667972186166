// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { User } from '../../interfaces/User/User';
import { useUser } from '../../hooks/useUser';
import { Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';

interface IStripePricingTableProps {
    email: User['email'];
    userId: User['id'];
    pricingId: string;
}

const MONTHLY_PRICING_ID = 'prctbl_1QHcxZJzuLXznZvBUtv6siXW';
const YEARLY_PRICING_ID = 'prctbl_1QHcvZJzuLXznZvBY0l07sM0';

const StripePricingTable = ({ email, userId, pricingId } : IStripePricingTableProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement('stripe-pricing-table', {
    'pricing-table-id': pricingId,
    'publishable-key': process.env.STRIPE_KEY,
    'customer-email': email,
    'client-reference-id': userId
  });
};

const PricingTableContainer = styled.div`
    min-width: 85rem;
`;

enum PricingTableTabs {
    MONTHLY='monthly',
    YEARLY='yearly'
}

export const PricingTable = () => {
  const { user } = useUser();
  const [pricingTableShown, setPricingTableShown] = useState(PricingTableTabs.YEARLY);
  return <PricingTableContainer>
    <Tabs
      value={pricingTableShown}
      onChange={(event, newValue) => setPricingTableShown(newValue)}
      centered
    >
      <Tab
        value={PricingTableTabs.MONTHLY}
        label="Monthly"
      />
      <Tab
        value={PricingTableTabs.YEARLY}
        label="Annual (17% off)"
      />
    </Tabs>
    <TabContext
      value={pricingTableShown}
    >
      <TabPanel value={PricingTableTabs.MONTHLY} role="tabpanel">
        {!!user &&
        <StripePricingTable
          email={user.email}
          userId={user.id}
          pricingId={MONTHLY_PRICING_ID}
        />
        }
      </TabPanel>
      <TabPanel value={PricingTableTabs.YEARLY} role="tabpanel">
        {!!user &&
        <StripePricingTable
          email={user.email}
          userId={user.id}
          pricingId={YEARLY_PRICING_ID}
        />
        }
      </TabPanel>
    </TabContext>
  </PricingTableContainer>;
};
