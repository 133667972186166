import axios, { AxiosInstance } from 'axios';
import { getBaseApiUrl } from '../utilities/BaseApiUrl/BaseApiUrl';

const getSessionInstance = (jwt?: string|null): AxiosInstance => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.baseURL = getBaseApiUrl();

  if (jwt) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  }

  return axiosInstance;
};

class ApiService {
    axiosInstance = getSessionInstance();

    updateToken = (token: string) => {
      this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    getAxios = () => {
      return this.axiosInstance;
    }
}

export const talentViewApi = new ApiService();
