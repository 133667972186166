import { Route } from 'react-router-dom';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { useEffect, useState } from 'react';
import { UserContext } from '../../contexts/User/User';
import { APP_ROUTES, PUBLIC_ROUTES } from '../../constants/Routes';
import { talentViewApi } from '../../services/ApiService';
import { useGetCreditsUsage } from '../../hooks/useCredits';
import { UserCreditsContext } from '../../contexts/Credits/UserCreditsContext';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { User } from '../../interfaces/User/User';
import { FinishAccountDialog } from '../FinishAccountDialog/FinishAccountDialog';

const AUTH_CHECK_API = '/authentication/check';

// eslint-disable-next-line react/prop-types
export const AuthenticatedRoute = withAuthenticationRequired(({ children, ...rest }) => {
  const [userData, setUserData] = useState<User|null>(null);
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [isInited, setInited] = useState(false);
  const [showFinishAccountDialog, setShowFinishAccountDialog] = useState(false);

  const checkForAuthentication = () => {
    talentViewApi.getAxios()(AUTH_CHECK_API)
      .then(response => {
        if (response && response.data && response.data.success) {
          setUserData(response.data.data);

          if (!response.data.data.organizationId) {
            setShowFinishAccountDialog(true);
          }
        }
      })
      .catch(async error => {
        if (error.response?.status === 401) {
          await setAxiosToken();
        }
      });
  };

  useEffect(() => {
    const checkAuthOnTabReFocus = () => {
      if (document.visibilityState === 'visible') {
        checkForAuthentication();
      }
    };

    document.addEventListener('visibilitychange', checkAuthOnTabReFocus);

    return () => {
      document.removeEventListener('visibilitychange', checkAuthOnTabReFocus);
    };
  }, []);

  const setAxiosToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://localhost:8080'
        }
      });
      talentViewApi.updateToken(token);
    } catch (e) {
      await logout({
        logoutParams: {
          returnTo: window.location.origin + PUBLIC_ROUTES.LOGIN()
        }
      });
    }
  };

  const handleResponseError = async (error: any) => {
    if (error.response?.status === 401) {
      await setAxiosToken();
    } else {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setAxiosToken().then(() => {
        setInited(true);
        checkForAuthentication();
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const existingInterceptor = talentViewApi.getAxios().interceptors.response.use(response => response, error => {
        return handleResponseError(error);
      });

      return () => {
        talentViewApi.getAxios().interceptors.response.eject(existingInterceptor);
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isInited) {
      checkForAuthentication();
    }
  }, [user]);

  const {
    data: creditUsage,
    isFetched,
    isLoading: isLoadingCreditUsage,
    isRefetching: isRefetchingCreditUsage
    // @ts-ignore
  } = useGetCreditsUsage(!!userData);

  const handleFinishAccountClose = () => {
    setShowFinishAccountDialog(false);
    checkForAuthentication();
  };

  return (
    <Route
      path={APP_ROUTES.BASE()}
      render={() => {
        return <UserContext.Provider value={userData as User}>
          <UserCreditsContext.Provider value={{
            creditUsage,
            isLoading: isLoadingCreditUsage,
            isRefetching: isRefetchingCreditUsage,
            isInited: isInited && isFetched
          }}>
            {isInited && children}
            <FinishAccountDialog open={showFinishAccountDialog} onClose={handleFinishAccountClose} />
            {!isInited && <LoadingAnimation isVisible />}
          </UserCreditsContext.Provider>
        </UserContext.Provider>;
      }}
    />
  );
});
