import styled from 'styled-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup } from '@material-ui/core';

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.palette.primary.light};
  padding: 0;
  
  h2 {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0 1rem;
    flex: 0 0 auto;
  }
  
  button {
    margin-left: auto;
    padding: 1rem;
    flex: 0 0 auto;
  }
`;

export const DialogHead = styled.h2`
  margin-left: 16px !important;
`;

export const DialogRadioGroup = styled(RadioGroup)`
  label {
    margin: 0;
  }
  
  label + label {
    margin-top: 0.5rem;
  }
  
  .MuiRadio-root {
    padding: 0;
    margin-right: .5rem;
  }
`;

export const DialogFormActions = styled.div`
  grid-column-start: 2;
  margin-left: auto;
  
  button + button {
    margin-left: 1rem;
  }
`;

export const DialogFormActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    grid-column-gap: .5rem;
    margin-top: 1rem;
`;

export const DialogForm = styled.form`
  display: grid;
  grid-template-columns: 13rem 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-top: 2rem;
`;

export const StyledDialogContent = styled(DialogContent)`
  p {
    text-align: center;
  }
`;

export const StyledDialog = styled(Dialog)` 
  .MuiDialog-paper {
    width: 40rem;
  }
`;

export const StyledSaveDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 20rem;
  }
`;

export const DialogSaveForm = styled.form`
display: grid;
grid-template-columns: 5rem 1fr;
grid-column-gap: 0rem;
grid-row-gap: 0rem;
margin-top: 0rem;
`;

export const AdminDialogActions = styled(DialogActions)`
  padding-right: 24px;
  padding-bottom: 16px;
`;

export const AdminDialogTitle = styled(DialogTitle)`
  padding-bottom: 0;
`;

export const AdminDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding-top: 0 !important;
`;
