import { Link } from '@material-ui/core';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const LabelWrapper = styled.span`
  position: relative;
  white-space: pre-wrap;
  
  &:before {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    height: 6px;
    background-color: ${props => props.theme.palette.secondary.main};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

const SUB_NAV_LINK_STYLES = css`
  position: relative;
  display: block;
  padding: 1rem;
  font-weight: 600;
  white-space: nowrap;
  
  &:not(:first-child) {
    border-top: 1px solid rgba(0,0,0,.25);
  }

  ${LabelWrapper}:before {
    opacity: 0;
  }

  &.active {
    ${LabelWrapper}:before {
      opacity: 1;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:not(.active):hover {
    ${LabelWrapper}:before {
      opacity: .5;
    }
  }
`;

const AppSubNavLink = styled(NavLink)`${SUB_NAV_LINK_STYLES}`;

const ExternalSubNavLink = styled.a`${SUB_NAV_LINK_STYLES}`;

const DisplaySubNavLink = styled.div`
  ${SUB_NAV_LINK_STYLES}
  &:not(.active):hover {
    ${LabelWrapper}:before {
      opacity: 0;
    }
  }
`;

export const AppSubNavigationListWrapper = styled.ul`
  list-style: none;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 100%;
  right: 0;
  background-color: #fff;
  z-index: 1;
  border: 1px solid rgba(0,0,0,.25);
`;

export enum SubNavItemType {
    APP,
    EXTERNAL,
    DISPLAY,
}

export interface IAppSubNavigationItem {
    to?: string,
    label: string,
    type: SubNavItemType,
}

interface AppSubNavigationListProps {
    subNavItems: IAppSubNavigationItem[],
}

export const AppSubNavigationList = ({ subNavItems } : AppSubNavigationListProps) => {
  return <AppSubNavigationListWrapper>
    {subNavItems.map(navItem => <AppSubNavigationItem key={navItem.label} subNavItem={navItem} />)}
  </AppSubNavigationListWrapper>;
};

interface IAppSubNavigationItemProps {
    subNavItem: IAppSubNavigationItem
}

const AppSubNavigationItem = ({ subNavItem }: IAppSubNavigationItemProps) => (
  <>
    {subNavItem.type === SubNavItemType.DISPLAY && <DisplaySubNavLink><LabelWrapper>{subNavItem.label}</LabelWrapper></DisplaySubNavLink>}
    {subNavItem.type === SubNavItemType.EXTERNAL && <Link component={ExternalSubNavLink} href={subNavItem.to} target='_blank' color='textPrimary'><LabelWrapper>{subNavItem.label}</LabelWrapper></Link>}
    {subNavItem.type === SubNavItemType.APP && <Link component={AppSubNavLink} to={subNavItem.to} color='textPrimary'><LabelWrapper>{subNavItem.label}</LabelWrapper></Link>}
  </>
);
