// eslint-disable-next-line no-use-before-define
import React, { Suspense, useEffect } from 'react';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';

const LoadedUserSettingsPage = React.lazy(() => import('./UserSettingsPage').then(({ UserSettingsPage }) => ({ default: UserSettingsPage })));

export const LoadableUserSettingsPage = () => {
  useEffect(() => setPageTitle('Profile'), []);

  return <Suspense fallback={<LoadingAnimation />}>
    <LoadedUserSettingsPage />
  </Suspense>;
};
