import { ApiResponse } from '../interfaces/Api';
import { talentViewApi } from './ApiService';

const BASE_API = '/payments';

export class PaymentsService {
  public static async getManageSubscriptionLink () : Promise<ApiResponse<string>> {
    const response = await talentViewApi.getAxios().get(`${BASE_API}/manage-subscription`);
    return response.data;
  }

  public static async cancelSubscription () : Promise<ApiResponse<string>> {
    const response = await talentViewApi.getAxios().post(`${BASE_API}/cancel-subscription`);
    return response.data;
  }
}
