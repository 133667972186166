export const ANALYTICS_EVENT = {
  EXPORT: 'Export',
  LOGIN: 'Login',
  ADVANCED_SEARCH: 'Advanced Search',
  DETAIL_PAGE_VIEWED: 'Details Page Viewed'
};

export const ANALYTICS_PROPERTIES = {
  ORGANIZATION: 'organization',
  ORGANIZATION_NAME: 'organization_name',
  ROLE: 'role',
  ENVIRONMENT: 'environment'
};
