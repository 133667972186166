// eslint-disable-next-line no-use-before-define
import React, { lazy, Suspense, useEffect } from 'react';
import { setPageTitle } from '../../utilities/PageTitleUtil/PageTitleUtil';
import { LoadingAnimation } from '../../elements/LoadingAnimation/LoadingAnimation';

const LoadedEmailCampaignsDashboardPage = lazy(() => import('./EmailCampaignsDashboardPage').then(({ EmailCampaignsDashboardPage }) => ({ default: EmailCampaignsDashboardPage })));

export const LoadableEmailCampaignsDashboardPage = () => {
  useEffect(() => {
    setPageTitle('Email Campaigns');
  }, []);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <LoadedEmailCampaignsDashboardPage />
    </Suspense>
  );
};
