import { CreditUsageDTO } from '../interfaces/Credits/Credits';
import { talentViewApi } from './ApiService';
import { ApiResponse } from '../interfaces/Api';

const API_BASE = '/credits';

export class CreditsService {
    public static getCreditUsage = async (): Promise<CreditUsageDTO> => {
      const response = await talentViewApi.getAxios().get(`${API_BASE}/usage`);
      return response?.data?.data;
    }

    public static unlockContactInfo = async (contactId: string): Promise<ApiResponse<CreditUsageDTO>> => {
      const response = await talentViewApi.getAxios().post(`${API_BASE}/unlock-contact/${contactId}`);
      return response.data;
    }
}
