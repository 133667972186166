import { AnalyticsService, IAnalyticsEvent } from '../services/AnalyticsService';
import { IAnalyticsProperties } from '../interfaces/Analytics/Analytics';

export const useEventTracking = <TAnalyticsProperties extends IAnalyticsProperties>() => {
  return {
    trackEvent: (event: IAnalyticsEvent<TAnalyticsProperties>) => AnalyticsService.trackEvent<TAnalyticsProperties>(event)
  };
};

export const useAnalyticsUser = () => {
  return {
    setAnalyticsUser: AnalyticsService.setUserDetails
  };
};
